import React, { useEffect, useState } from "react";
import IcnBell from "../../assets/svg/IcnBell";
import useAxiosPrivate from "../../api/useAxiosPrivate";
import { USER_API_URL } from "../../api/api";
import { useQuery } from "react-query";
import Loader from "../../components/ui/Loader";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Button } from '../../components/ui/Button';
import TransactionDetailModal from "../../components/model/TransactionDetailModal";

const Notification = () => {
    const axiosInstance = useAxiosPrivate();
    const [notification, setNotification] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isdataLoading, setIsdataLoading] = useState(false);
    const [page, setPage] = useState(1);
    const navigate = useNavigate();
    const [openTransactionDetailModal, setOpenTransactionDetailModal] = useState(false);
    const [nevData, setNevData] = useState();
    const [currentPage, setCurrentPage] = useState(0);
    const [totalData, setTotalData] = useState(0);
    const [limit, setLimit] = useState(5);
    const [loadingMoreData, setLoadingMoreData] = useState(false);

    const getNotification = async () => {
        try {
            const response = await axiosInstance.get(`${USER_API_URL.getNotificationWeb}?limit=${5}&page=${currentPage + 1}`);
            for (const notification of response?.data?.notifications) {
                const { isRead, data, id } = notification;
                if (!isRead) {
                    try {
                        const bodyData = { 'notificationId': id }
                        await axiosInstance.post(USER_API_URL.markAsRead, bodyData)
                    } catch (error) {
                        console.error('Error calling API:', error);
                    }
                }
            }
            return response?.data;
        } catch (error) {
            console.log(error);
            return null;
        }
    };

    const { isLoading: isLoadingTran, data: notificationData, refetch } = useQuery(
        ['notificationData', limit, currentPage],
        getNotification,
        { keepPreviousData: true }
    );

    useEffect(() => {
        if (notificationData) {

            setNotification(notifications => [...notifications, ...notificationData?.notifications]);
            setTotalData(notificationData?.metaData?.total);
            setLoadingMoreData(false);
            setIsLoading(false);
        }
    }, [notificationData]);

    const handleLoadMore = () => {
        setCurrentPage((prevPage) => prevPage + 1);
        setLoadingMoreData(true);
    };


    const handleNavigation = async (data) => {
        if (data?.data?.type == 7 && (data?.data?.status == 3 || data?.data?.status == 5)) {
            navigate('/dashboard/kyc');
        }

        if (data?.data?.type == 5 || data?.data?.type == 6) {
            const response = await axiosInstance.get(`${USER_API_URL.broadcastTransactionsDetails}?txId=${data?.data?.txId}`)
            if (response) {
                setOpenTransactionDetailModal(true)
                setNevData(response?.data);
            }
        }

    }


    return (
        <div className="p-12 px-20 bg-dark h-full ">
            {isLoading ? <Loader /> :
                notification?.length > 0 ?
                    <div className="bg-card h-full rounded-[20px] border border-light-100 p-[8px] max-h-[calc(100vh-140px)] overflow-auto hidden-scrollbar">
                        {
                            notification.map((data, index) =>
                                <div className="rounded-[20px] border border-light-100 py-[6px] px-[8px] mb-[10px] last:mb-0">
                                    <div className="flex items-start gap-[16px]" onClick={() => handleNavigation(data)}>
                                        <div className="h-[40px] w-[40px] bg-white/10 rounded-full text-white flex items-center justify-center flex-shrink-0">
                                            <IcnBell className='h-[24px] w-[24px] text-white' />
                                        </div>
                                        <div className="">
                                            <h6 className="text-white text-[18px] font-500">{data?.notification?.title}</h6>
                                            <p className="text-white text-[14px] font-400">{data?.notification?.body}</p>
                                        </div>
                                    </div>
                                    <p className="text-white text-end">{moment(data?.createdAt).format('DD-MM-YYYY h:mm')}</p>
                                </div>
                            )
                        }
                        <div className='flex items-center justify-center'>
                            {/* <div className='text-white cursor-pointer text-[14px] font-300 m-5 p-[6px_14px] rounded-[24px] bg-theme' onClick={handleLoadMore} disabled={loadingMoreData} style={{ float: 'right' }}>
                                {isdataLoading ? "Please wait..." : "Load more..."}
                            </div> */}
                            {notification?.length !== totalData &&
                            <Button className="text-[12px]" onClick={handleLoadMore} disabled={loadingMoreData}>
                                {loadingMoreData ? 'Loading...' : 'Load More'}
                            </Button>
                            }
                        </div>
                    </div>
                    : <div className=" w-full text-center"><h5 className='text-white gap-[10px] text-[16px] m-auto'>Record Not Found!</h5></div>

            }
            <TransactionDetailModal
                open={openTransactionDetailModal}
                handleClose={() => setOpenTransactionDetailModal(false)}
                detailData={nevData}
                plan='coins'
            />
        </div>

    )
}


export default Notification