import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import IconClose from '../../assets/svg/IconClose'

const FullCardDetailModal = ({ open, handleClose, detail }) => {

  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>Full Card Details</p>
                  <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>
                <div className="p-12">
                  <h6 className='text-gray text-14 mb-4'>Name</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.firstName} {detail?.lastName}</p>
                  <h6 className='text-gray text-14 mb-4'>Email Address</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.email}</p>
                  <h6 className='text-gray text-14 mb-4'>Card Number</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.cardNumber}</p>
                  <h6 className='text-gray text-14 mb-4'>Single Limit</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.maxAmountSingle ?? '-'}</p>
                  <h6 className='text-gray text-14 mb-4'>Daily Limit</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.maxAmountDaily ?? '-'}</p>
                  <h6 className='text-gray text-14 mb-4'>Monthly Limit</h6>
                  <p className='text-white pb-4 border-b border-b-light-200 mb-4'>{detail?.maxAmountMonthly ?? '-'}</p>

                  <h6 className='text-gray text-14 mb-4'>Account Status</h6>
                  <p className='text-theme pb-4 border-b border-b-light-200 mb-4'>{detail?.cardIdStatus == 0 ? 'Pending' : detail?.cardIdStatus == 1 ? 'Verified' : 'Rejected'}</p>
                  
                  <h6 className='text-gray text-14 mb-4'>Freeze/UnFreeze</h6>
                  <p className='text-theme pb-4 border-b border-b-light-200 mb-4'>{detail?.cardFreezeStatus == 2 ? 'Unfreeze' : 'Freeze'}</p>
                  <ul className='flex items-center justify-center list-disc'>
                    {detail?.cardFreezeRequestStatus && <li className='text-[#cfba54] text-[16px]'> {detail?.cardFreezeRequestStatus == 0 ? ' Your freez card request is in under process.' : detail?.cardFreezeRequestStatus == 2 ? 'Your freez card request is rejected' : detail?.cardFreezeRequestStatus == 99 ? 'Please waiting for third party' : detail?.cardFreezeRequestStatus == 99 ? 'Pending payment' : ''} </li>}
                  </ul>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

export default FullCardDetailModal
