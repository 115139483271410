import React, { useEffect, useState } from 'react'
import { Button } from '../../components/ui/Button'
import IconCamera from "../../assets/svg/IconCamera"
// import ProfileImg from "../../assets/images/Profile.png"
import Input from '../../components/ui/Input'
import PhoneInput from 'react-phone-input-2'
import { Form, FormikProvider, useFormik } from 'formik'
import BasicFileUpload from "../../components/ui/BasicFileUpload";
import { USER_API_URL } from '../../api/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useSnackbar } from 'notistack'
import UseStore, { authStore } from '../../context/AuthProvider'
import { files } from '../../Helper'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import Loader from '../../components/ui/Loader'
import { t } from 'i18next'
const Setting = () => {
  const [phone, setPhone] = useState();
  const [countryCode, setCountryCode] = useState("90"); // Set the default country code to 'in'
  const [ProfileImg, setProfileImg] = useState();
  const [Loading, setLoading] = useState();
  const [PreImg, setPreImg] = useState();
  const [selectedImg, setSelectedImg] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const setUser = authStore((state) => state?.setUser)
  const [imageName, setImageName] = useState();
  const { user } = authStore();
  const axiosInstance = useAxiosPrivate();
  const queryClient = useQueryClient();

  async function getUserProfile() {
    const response = await axiosInstance.get(USER_API_URL.userProfile);
    const updatedUser = {
      ...user,
      ...response?.data
    };
    setUser(updatedUser);
    return response.data;
  }
  const { isLoading, data: userData, refetch } = useQuery(['userProfile'], () => getUserProfile(), { keepPreviousData: true, })


  useEffect(() => {
    if (userData) {
      const updatedUser = {
        ...user,
        ...userData
      };
      setUser(updatedUser);
      setProfileImg(files(userData?.profile, "image"))
      setPreImg(files(userData?.profile, "image"))
      if (userData?.countryCode !== null && userData?.mobile !== null) {
        setPhone(userData?.countryCode + userData?.mobile);
      }
      setValues({
        ...formik.values,
        country_code: userData?.countryCode || '',
        mobile_number: userData?.mobile || '',
      })
    }

  }, [userData])





  //  Image upload events
  const handleFileInputOnChange = (event) => {
    event.preventDefault();
    const selectedFile = event?.target?.files[0];
    if (selectedFile.size > 1 * 1024 * 1024) {
      enqueueSnackbar('File size exceeds the maximum limit of 1 MB. Please choose a smaller file.', {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
      return;
    }
    setSelectedImg(event?.target?.files[0]);
    let userImgSrc = URL.createObjectURL(selectedFile);
    setPreImg(userImgSrc);
  }

  const { mutateAsync: uploadImage } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(USER_API_URL.uploadUserImage, data, {
          headers: {
            'Content-type': 'multipart/form-data',
          },
        });
        if (response) {
          setImageName(response?.data?.[0]);
          return response?.data?.[0]
        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors)?.map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    }
  );


  const { mutateAsync: updateProfie } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(USER_API_URL.updateProfile, data)
        if (response) {
          enqueueSnackbar(response?.data.message, {
            variant: "success",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
          setLoading(false);
          queryClient.invalidateQueries('userProfile')
        }
      } catch (error) {
        setLoading(false);
        if (error?.response?.data.errors) {
          const errors = error?.response?.data.errors;
          Object.keys(errors).map(function (key) {
            enqueueSnackbar(errors[key], {
              variant: "error",
              anchorOrigin: { vertical: "top", horizontal: "right" },
              autoHideDuration: 2000,
            });
          });
        }
      }
    }
  );


  const handlePhoneChange = (value, data, event, formattedValue) => {
    const countryCode = data?.dialCode;
    const phoneNumber = value?.replace(`+${countryCode}`, '');
    setCountryCode(countryCode)
    setPhone(phoneNumber);
    setValues({
      ...formik.values,
      country_code: countryCode,
      mobile_number: phoneNumber,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user?.name !== null ? user?.name : "",
      user_name: user?.userName !== undefined ? user?.userName : "",
      country_code: "",
      mobile_number: user ? userData?.countryCode + userData?.mobile : "",
      email: user ? user?.email : "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      let response = '';
      if (selectedImg) {
        const formData = new FormData();
        formData.append("images", selectedImg)
        response = await uploadImage(formData);
        setImageName(response);
      }
      let numbersAfterCountryCode;
      if (phone?.startsWith(countryCode)) {
        numbersAfterCountryCode = phone?.slice(countryCode?.length);
      }
      const data = {
        name: values?.name,
        user_name: values?.user_name,
        country_code: !numbersAfterCountryCode ? '90' : countryCode,
        mobile_number: numbersAfterCountryCode ? numbersAfterCountryCode : "",
        email: values?.email,
        oldImage: response && user?.profile || '',
        image: response ? response : user?.profile
      }
      await updateProfie(data);
    },
  });
  const {
    handleSubmit,
    getFieldProps,
    setValues
  } = formik;



  return (
    <>
      {isLoading ? <Loader /> :
        <div className="bg-card min-h-full mx-auto flex flex-col p-12">
          <FormikProvider value={formik}>
            <Form autoComplete="off" onSubmit={handleSubmit}>
              <div className="relative w-max mx-auto mb-20">
                <BasicFileUpload
                  accept="image/png, image/jpeg"
                  avatar={ProfileImg}
                  PreImg={PreImg}
                  userData={userData}
                  onChange={handleFileInputOnChange}
                />
              </div>
              <Input
                className="mb-8 bg-dark"
                lableClass="text-14"
                lable={t("Name")}
                placeholder="Enter Name"
                {...getFieldProps('name')}
              />
              <Input
                className="mb-8 bg-dark"
                lableClass="text-14"
                lable={t("User_Name")}
                placeholder="Enter User Name"
                {...getFieldProps('user_name')}

              />
              <Input
                disabled
                className="mb-8 bg-dark truncate !pe-[76px]"
                lableClass="text-14"
                lable={t("Email_Address")}
                placeholder="Enter New Email Address"
                suffix={
                  <span>
                    Verified
                    {/* {user?.isEmailEnable ? 'Verified' : 'Un-Verified'} */}
                  </span>
                }
                {...getFieldProps('email')}

              />
              <label htmlFor="" className="!text-white text-14 font-500 mb-4 block">
                {t("Mobile_Number")}
              </label>
              <div className="bg-dark rounded-full p-3 flex items-center w-full relative border border-light-100">
                <PhoneInput
                  country={'tr'}
                  value={phone}
                  className="w-full"
                  inputClass="!bg-transparent !border-0 text-white w-full p-[14px] rounded-full"
                  buttonClass="!bg-transparent !border-0 hover:!bg-transparent"
                  onChange={handlePhoneChange}
                />
              </div>
              <div className="mt-auto">
                <Button size="sm" className={`w-full mt-20 text-white text-[14px] ${Loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                  {Loading ? t("loading") : t("Save_Change")}
                </Button>
              </div>
            </Form>
          </FormikProvider>

        </div>}
    </>
  );
};

export default Setting;
