import React, { useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { Button } from '../../components/ui/Button';
import IconAdd from "../../assets/svg/IconAdd";
import IconArrow from "../../assets/svg/IconArrow";
import SearchIcon from '../../assets/images/SearchIcon.png'
import ApiDetailModal from '../../components/model/ApiDetailModal';
import Input from '../../components/ui/Input';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { APP_API_URL, PAYMENT_LINK_API_URL } from '../../api/api';
import useAxiosPrivate from '../../api/useAxiosPrivate';
import { useQuery } from 'react-query';
import { dateFormatter } from '../../Helper';
import Loader from '../../components/ui/Loader';
import { useSnackbar } from 'notistack';
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';

const Api = () => {
  const { t } = useTranslation();
  const [plan, setPlan] = useState('apps');
  const [openApiDetailModal, setOpenApiDetailModal] = useState(false);
  const [apps, setApps] = useState();
  const [apiRequests, setApiRequests] = useState()
  const [callBacks, setCallBacks] = useState();
  const [totalapps, setTotalapps] = useState();
  const [appDetail, setAppDetail] = useState();
  const [search, setSearch] = useState("");
  const [Payments, setPayments] = useState();
  const navigate = useNavigate();
  const axiosInstance = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const [currentPage, setCurrentPage] = useState(1);
  const [Page, setPage] = useState(0);

  useEffect(() => {
    document.title = 'Api | Oppi Wallet'
  }, [])

  const createApi = () => {
    if (Payments && Payments.isAppBlock == 2) {
      Swal.fire({
        title: 'You are not able to create more apps, for more information contact to support!',
        icon: 'question',
        // showCancelButton: true,
        confirmButtonText: 'Okay',
        // cancelButtonText: 'Cancel',
        customClass: {
          confirmButton: 'confirm-button-class',
          cancelButton: 'confirm-button-class'
        }
      })

    } else {
      navigate("/dashboard/create-api");
    }
  }
  const handleOpenApiDetailModal = (appDetails) => {

    setAppDetail(appDetails)
    setOpenApiDetailModal(true)
  }
  const handleCloseApiDetailModal = () => {
    setOpenApiDetailModal(false)
  }

  async function getAppList() {
    const response = await axiosInstance.get(`${APP_API_URL.appList}?page=${currentPage}`);
    return response.data;
  }
  const { data: appList, refetch } = useQuery(['appList'], () => getAppList(), { keepPreviousData: true, })

  useEffect(() => {
    if (appList) {
      setApps(appList?.AppliedApis);
      setTotalapps(appList?.metaData)
    }
  }, [appList])

  useEffect(() => {
    refetch();
  }, [currentPage, refetch]);

  async function totalPayments() {
    const res = await axiosInstance.get(PAYMENT_LINK_API_URL.totalPayments);
    if (res?.status === 200) {
      setPayments(res?.data)
    } else {
      console.log('error');
    }
  }

  useEffect(() => {
    totalPayments();
  }, [])


  async function getApiReq() {
    const response = await axiosInstance.get(APP_API_URL.apiRequestList);
    return response.data;
  }
  const { data: apiReqList, refetch: refetchApiReqList } = useQuery(['apiReqList'], () => getApiReq(), { keepPreviousData: true, })

  useEffect(() => {
    if (apiReqList) {
      setApiRequests(apiReqList);
    }
  }, [apiReqList])


  async function getApiCallBacks() {
    const response = await axiosInstance.get(APP_API_URL.apiCallbackList);
    return response.data;
  }
  const { isLoading, data: apiCallBackList, refetch: refetchApiCallBackList } = useQuery(['apiCallBackList'], () => getApiCallBacks(), { keepPreviousData: true, })

  useEffect(() => {
    if (apiCallBackList) {
      setCallBacks(apiCallBackList);
    }
  }, [apiCallBackList])

  const serachbutton = (e) => {
    const searchValue = e.target.value
    setSearch(searchValue);
  }

  useEffect(() => {
    if (plan === "api-request") {
      const filteredList = apiReqList.filter(item => {
        for (const key in item) {
          if (item[key].toString().toLowerCase().includes(search)) {
            return true;
          }
        }
        return false;
      });
      setApiRequests(filteredList)
    } else if (plan === "callbacks") {
      const filteredList = callBacks.filter(item => {
        for (const key in item) {
          if (item[key].toString().toLowerCase().includes(search)) {
            return true;
          }
        }
        return false;
      });
      setCallBacks(filteredList)
    }
  }, [search])

  useEffect(() => {
    setSearch('');
  }, [plan])

  const items = plan == 'apps' ? apps : '';

  const itemsPerPage = 7;
  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
const currentItems = items?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items?.length / itemsPerPage);

  const handlePageClick = (event) => {
    setCurrentPage(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % items?.length;
    setItemOffset(newOffset);
  };

  function checkStatus(data) {
    if (data?.status == 1 && data?.adminStatus == 1) {
      return "Active"
    } else if (data?.adminStatus == 0) {
      return "Pending"
    } else if (data?.status == 2) {
      return "Inactive"
    } else {
      return "Rejected"
    }
  }

  return (

    <div className="p-12 bg-dark h-full">
      <div className="flex items-center md:flex-row flex-col md:gap-[24px] gap-[12px] pb-[20px] ">
        <div className="bg-card border border-light-100 p-[16px_10px] rounded-[12px] md:w-1/3 w-full">
          <p className='text-[14px] text-white font-400 mb-[4px]'>{t('Daily_Payments')}</p>
          <h6 className='text-[18px] text-theme'>{Payments?.dailyPayments !== 0 ? Payments?.dailyPayments.toFixed(2) : 0.00} USD</h6>
        </div>
        <div className="bg-card border border-light-100 p-[16px_10px] rounded-[12px] md:w-1/3 w-full">
          <p className='text-[14px] text-white font-400 mb-[4px]'>{t('Weekly_Payments')}</p>
          <h6 className='text-[18px] text-theme'>{Payments?.weeklyPayments !== 0 ? Payments?.weeklyPayments.toFixed(2) : 0.00} USD</h6>
        </div>
        <div className="bg-card border border-light-100 p-[16px_10px] rounded-[12px] md:w-1/3 w-full">
          <p className='text-[14px] text-white font-400 mb-[4px]'>{t('Revenue')}</p>
          <h6 className='text-[18px] text-theme'>{Payments?.percentages !== 0 ? Payments?.percentages.toFixed(2) : 0.00}% ({Payments?.revenueAmount !== 0 ? Payments?.revenueAmount.toFixed(2) : 0.00} USD)</h6>
        </div>
      </div>
      <div className="md:flex block  justify-between items-center xl:mb-12 mb-[8px] gap-12">
        <RadioGroup className="flex text-20 gap-3 bg-dark rounded-full md:mb-0 mb-[8px] flex-wrap" value={plan} onChange={setPlan}>
          <RadioGroup.Option className="cursor-pointer" value="apps">
            {({ checked }) => (
              <Button size="sm" className={`md:text-[16px] text-[12px] md:py-4 py-3 px-[12px] w-full border ${checked ? 'bg-[#7684ED] border-[#7684ED]' : 'bg-card border-light-100 text-gray'}`}>{t('Apps')}</Button>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option className="cursor-pointer" value="api-request">
            {({ checked }) => (
              <Button size="sm" className={`md:text-[16px] text-[12px] md:py-4 py-3 px-[12px] w-full border ${checked ? 'bg-[#7684ED] border-[#7684ED]' : 'bg-card border-light-100 text-gray'}`}>{t('Api_Request')}</Button>
            )}
          </RadioGroup.Option>
          <RadioGroup.Option className="cursor-pointer" value="callbacks">
            {({ checked }) => (
              <Button size="sm" className={`md:text-[16px] text-[12px] md:py-4 py-3 px-[12px] w-full border ${checked ? 'bg-[#7684ED] border-[#7684ED]' : 'bg-card border-light-100 text-gray'}`}>{t('Callbacks')}</Button>
            )}
          </RadioGroup.Option>
        </RadioGroup>
        {
          plan === "api-request" || plan === "callbacks" ? <div className="relative flex-grow xl:block hidden">
            <Input className="ps-16 py-4" placeholder="Search" onChange={(e) => serachbutton(e)} />
            <img className='absolute top-[12px] left-[16px] cursor-pointer' src={SearchIcon} alt="" />
          </div> : ""
        }
        <div className="flex gap-4 items-center justify-end">
          <Button className="!text-white flex gap-4 items-center md:text-[16px] text-[14px] md:py-4 py-3 border border-light-100" variant="gray" size="sm" onClick={createApi}> <IconAdd className="w-[16px]" /> {t('Create_Apps')} </Button>
        </div>
      </div>
      {
        plan === "api-request" || plan === "callbacks" ? <div className="relative flex-grow xl:hidden block mb-[8px]">
          <Input className="ps-16 py-4" placeholder="Search" onChange={(e) => serachbutton(e)} />
          <img className='absolute top-[12px] left-[16px] cursor-pointer' src={SearchIcon} alt="" />
        </div> : ""
      }
      <div className="flex flex-col gap-6">
        {
          plan === "apps" ? (
            isLoading ? <Loader /> : (

              apps?.length > 0 ?
                apps?.map((data, index) =>
                (
                  <><div className="p-6 w-full bg-card border border-light-100 flex justify-between items-center rounded-3xl cursor-pointer" onClick={() => handleOpenApiDetailModal(data)}>
                    <div className="flex justify-between items-center w-full">
                      <div className="text">
                        <h6 className='text-16 font-500 text-white mb-4'>{data?.title}</h6>
                        <p className='text-14 font-400 text-gray'>{dateFormatter(data?.createdAt)}</p>
                      </div>
                      <Button className={`!text-white px-4 py-1 border border-light-100  font-400
                      ${data?.status == 1 && data?.adminStatus == 1 ? 'bg-[#27A745]' : data?.adminStatus == 0 ? 'bg-[#ffaa2b]' : data?.status == 2 ? 'bg-[#782a2a]' : 'bg-red'}
                      flex gap-4 items-center text-14`} size="sm">
                        {checkStatus(data)}
                      </Button>
                    </div>
                  </div></>

                )) :
                <div className="flex items-center justify-center mt-10">
                  <h5 className='text-white flex items-center gap-[10px] text-[16px]'>Apps Not Found!</h5>
                </div>
            )
          )
            : plan === "api-request" ?
              <div className="overflow-x-auto">
                <table className='rounded-2xl overflow-hidden w-full min-w-[1000px]'>
                  <thead className='rounded-2xl overflow-hidden w-full border-2 border-light-100'>
                    <tr className='bg-card w-full flex divide-x-2 text-white text-12 divide-light-100'>
                      <td className='flex justify-between p-4 w-[25%]'>
                        ID
                      </td>
                      <td className='flex justify-between p-4 w-[25%]'>
                        App ID
                      </td>

                      <td className='flex justify-between p-4 w-[25%]'>
                        Created At
                      </td>

                      <td className='flex justify-between p-4 w-[25%]'>
                        Request Ip
                      </td>
                    </tr>
                  </thead>
                  <tbody className='rounded-2xl overflow-hidden w-full border-t-2 border-t-light-100 divide-y-2 divide-light-100 border-2 border-light-100'>
                    {
                      apiRequests?.length > 0 ?
                        apiRequests?.map((data, index) =>
                          <tr className='bg-card w-full flex divide-x text-gray text-12 divide-light-100'>
                            <td className='flex justify-between p-4 w-[25%]'>#{data?.id}</td>
                            <td className='flex justify-between p-4 w-[25%]'>{data?.appId}</td>
                            <td className='flex justify-between p-4 w-[25%]'>{dateFormatter(data?.createdAt)}</td>
                            <td className='flex justify-between p-4 w-[25%]'>{data?.requestIp}</td>
                          </tr>
                        ) :
                        <tr className='bg-card w-full flex divide-x text-gray text-12 divide-light-100'>
                          <td className='flex justify-center items-center p-9 w-[100%]'>Request Not Found!</td>
                        </tr>
                    }
                  </tbody>
                </table>
              </div>
              : plan === "callbacks" ?
                <div className="overflow-x-auto">
                  <table className='rounded-2xl overflow-hidden w-full min-w-[1000px]'>
                    <thead className='rounded-2xl overflow-hidden w-full border-2 border-light-100'>
                      <tr className='bg-card w-full flex divide-x-2 text-white text-12 divide-light-100'>
                        <td className='flex justify-between p-4 w-[20%]'>
                          ID
                        </td>
                        <td className='flex justify-between p-4 w-[20%]'>
                          Order ID
                        </td>

                        <td className='flex justify-between p-4 w-[20%]'>
                          Callback Status
                        </td>
                        <td className='flex justify-between p-4 w-[20%]'>
                          Retries
                        </td>
                        <td className='flex justify-between p-4 w-[20%]'>
                          Created At
                        </td>
                      </tr>
                    </thead>
                    <tbody className='rounded-2xl overflow-hidden w-full border-t-2 border-t-light-100 divide-y-2 divide-light-100 border-2 border-light-100'>
                      {
                        callBacks?.length > 0 ?
                          callBacks?.map((data, index) =>
                            <tr className='bg-card w-full flex divide-x text-gray text-12 divide-light-100'>
                              <td className='flex justify-between p-4  w-[20%]'>#{data?.id}</td>
                              <td className='flex justify-between p-4  w-[20%]'>4</td>
                              <td className='flex justify-between p-4  w-[20%]'>Pending</td>
                              <td className='flex justify-between p-4  w-[20%]'>No</td>
                              <td className='flex justify-between p-4  w-[20%]'>{dateFormatter(data?.createdAt)}</td>
                            </tr>
                          ) : <tr className='bg-card w-full flex divide-x text-gray text-12 divide-light-100'>
                            <td className='flex justify-center items-center p-9 w-[100%]'>Request Not Found!</td>
                          </tr>}
                    </tbody>
                  </table>
                </div> : ""
        }
      </div>
      {/* <IconArrow className="w-8" />
       */}
      <ApiDetailModal open={openApiDetailModal} handleClose={handleCloseApiDetailModal} appDetails={appDetail} />
      <div className="flex items-center justify-center mt-[25px]">

        <ReactPaginate
          breakLabel="..."
          nextLabel='>'
          onPageChange={handlePageClick}
          pageRangeDisplayed={itemsPerPage}
          pageCount={Math.ceil(totalapps?.total / itemsPerPage)}
          previousLabel='<'
          renderOnZeroPageCount={null}
          className="text-white flex items-center gap-[10px] text-[14px]"
          marginPagesDisplayed={1}
          previousClassName="text-[20px]"
          nextClassName="text-white flex items-center gap-[10px] text-[20px]"
          activeClassName="flex items-center justify-center gap-[10px] text-[14px] h-[24px] rounded-[4px] text-theme border border-theme px-[6px]"
        />
      </div>
      {/* loader */}
      {/* <Loader /> */}
    </div>
  )
}

export default Api