import { Dialog, Transition, Listbox } from '@headlessui/react'
import { Fragment, useState } from 'react'
import IconClose from '../../assets/svg/IconClose'
import { _randomString, } from '../../Helper'
import IconRight from '../../assets/svg/IconRight'
import moment from 'moment'
import { Button } from '../ui/Button'
import { useMutation } from 'react-query'
import { axiosInstance } from '../../api/axiosInstance'
import { USER_API_URL } from '../../api/api'
import { enqueueSnackbar } from 'notistack'
import Loader from '../ui/Loader'

var cardTypeJson = {
  1: "Consume",
  2: "Recharge",
  3: "Withdrawal",
  4: "Transfer (In)",
  5: "Transfer (Out)",
  6: "Other",
  7: "Settlement adjustment",
  8: "Refund",
  9: "Payment reversal",
  10: "Fee",
  11: "Fee reversal",
  12: "OTC refund",
  13: "OTC refund reversal",
  14: "Consumption failure",
  15: "Binding card verification transaction",
  16: "Transaction service fee",
  17: "Rescission",
  100: "Credit card bill reconciliation",
  101: "Purchase Crypto Coin",
  102: "Cancel Card",
};

var cardStatus = {
  0: 'pending',
  1: 'success',
  2: 'fail'

}

const SetTransectionListModal = ({ open, handleClose, transactionList, setTransactionList, setSelectedMonth, setSelectedYear, setMySelectedTime, handleApply, yearsArray,
  selectedMonth,
  selectedYear, loading,
  mySelectedTime, monthNames



}) => {



  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={handleClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
          </Transition.Child>
          <div className="fixed inset-0">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark border border-light-100 shadow-xl transition-all ">
                  <div className="flex justify-center p-8 bg-card border-b-light-100 border-b rounded-t-[30px]">
                    <p className='text-white ms-auto'>View Transaction List</p>
                    <IconClose onClick={handleClose} className="text-white w-[18px] ms-auto cursor-pointer"
                    />
                  </div>
                  <div className="">
                    <div className="p-[12px] sm:px-[24px] px-[12px]">
                      <div className="bg-card rounded-[10px] p-[10px] border border-light-100">
                        <div className="flex gap-[8px]">
                          <Listbox value={selectedMonth} onChange={setSelectedMonth} className='w-full'>
                            <div className="relative mt-1">
                              <Listbox.Button className="relative w-full cursor-default rounded-[24px] bg-card text-white py-[12px] px-[10px] text-left text-[12px] border border-light-100">
                                <span className="block truncate">{selectedMonth ? selectedMonth : "select"}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[10px]">
                                  <IconRight className='h-[12px] w-[12px] text-white rotate-90' aria-hidden="true" />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute mt-1 max-h-60 theme-scrollbar w-full overflow-auto rounded-md bg-card py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                  {monthNames.map((month, monthIdx) => (
                                    <Listbox.Option
                                      key={monthIdx}
                                      className={({ active }) =>
                                        `relative cursor-pointer select-none py-2 pl-10 pr-4 text-[10px] ${active ? 'bg-dark text-gray' : 'text-gray'
                                        }`
                                      }
                                      value={month}
                                    >
                                      {({ selectedYear }) => (
                                        <>
                                          <span
                                            className={`block truncate ${selectedMonth ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                            {month}
                                          </span>
                                          {selectedYear ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                              {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                          <Listbox value={selectedYear} onChange={setSelectedYear} className='w-full'>
                            <div className="relative mt-1">
                              <Listbox.Button className="relative w-full cursor-default rounded-[24px] bg-card text-white py-[12px] px-[10px] text-left text-[12px] border border-light-100">
                                <span className="block truncate">{selectedYear ? selectedYear : "select"}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-[10px]">
                                  <IconRight className='h-[12px] w-[12px] text-white rotate-90' aria-hidden="true" />
                                </span>
                              </Listbox.Button>
                              <Transition
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options className="absolute mt-1 max-h-60 theme-scrollbar w-full overflow-auto rounded-md bg-card py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                  {yearsArray.map((year, yearIdx) => (
                                    <Listbox.Option
                                      key={yearIdx}
                                      className={({ active }) =>
                                        `relative cursor-pointer select-none py-2 pl-10 pr-4 text-[10px] ${active ? 'bg-dark text-gray' : 'text-gray'
                                        }`
                                      }
                                      value={year}
                                    >
                                      {({ selectedYear }) => (
                                        <>
                                          <span
                                            className={`block truncate ${selectedYear ? 'font-medium' : 'font-normal'
                                              }`}
                                          >
                                            {year}
                                          </span>
                                          {selectedYear ? (
                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                              {/* <CheckIcon className="h-5 w-5" aria-hidden="true" /> */}
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </Listbox>
                        </div>
                        <Button onClick={() => handleApply()} className='p-[10px] rounded-[24px] text-[12px] bg-gray/20 w-full mt-[8px]'    >
                          Apply
                        </Button>

                      </div>
                    </div>
                    <div className="max-h-[320px] overflow-auto theme-scrollbar">
                      <ul className='sm:px-[24px] px-[12px]'>



                        {loading ?
                          <div className="min-h-[350px] flex items-center justify-center bg-card rounded-[20px] border border-light-400">
                            <Loader innarClass='mt-0' />
                          </div> :
                          transactionList?.length > 0 ? transactionList?.map((data, index) => {
                            const timestamp = (data?.transaction_date) * 1000; // Convert to milliseconds
                            const formattedDate = moment(timestamp).format('DD MMM, yyyy');
                            return (
                              <>
                              <li className='p-[12px_10px_12px] mb-[14px] flex justify-between cursor-pointer first:mt-[12px] bg-card rounded-[10px]'>
                                <div className="flex flex-col">
                                  <h5 className='text-white sm:text-[14px] text-[12px]'>{cardTypeJson[data?.type]}</h5>
                                  <p className='text-gray text-[12px]'>{formattedDate}</p>
                                </div>
                                <div className="flex items-end flex-col">
                                  <h5 className='text-white sm:text-14 text-12 uppercase'>{data?.tx_amount ? parseFloat(data?.tx_amount).toFixed(2) : '0.0'} {data?.tx_currency}</h5>
                                  <p className={`text-[12px] ${data?.status == 0 ? 'text-[#cfba54]' : data?.status == 1 ? 'text-green' : 'text-red'}`}>{cardStatus[data?.status]}</p>
                                </div>
                              </li>
                              <li className='p-[12px_10px_12px] mb-[14px] flex justify-between cursor-pointer first:mt-[12px] bg-card rounded-[10px]'>
                                <div className="flex flex-col">
                                  <h5 className='text-white sm:text-[14px] text-[12px]'>{cardTypeJson[data?.type]}</h5>
                                  <p className='text-gray text-[12px]'>{formattedDate}</p>
                                </div>
                                <div className="flex items-end flex-col">
                                  <h5 className='text-white sm:text-14 text-12 uppercase'>{data?.tx_amount ? parseFloat(data?.tx_amount).toFixed(2) : '0.0'} {data?.tx_currency}</h5>
                                  <p className={`text-[12px] ${data?.status == 0 ? 'text-[#cfba54]' : data?.status == 1 ? 'text-green' : 'text-red'}`}>{cardStatus[data?.status]}</p>
                                </div>
                              </li>
                              <li className='p-[12px_10px_12px] mb-[14px] flex justify-between cursor-pointer first:mt-[12px] bg-card rounded-[10px]'>
                                <div className="flex flex-col">
                                  <h5 className='text-white sm:text-[14px] text-[12px]'>{cardTypeJson[data?.type]}</h5>
                                  <p className='text-gray text-[12px]'>{formattedDate}</p>
                                </div>
                                <div className="flex items-end flex-col">
                                  <h5 className='text-white sm:text-14 text-12 uppercase'>{data?.tx_amount ? parseFloat(data?.tx_amount).toFixed(2) : '0.0'} {data?.tx_currency}</h5>
                                  <p className={`text-[12px] ${data?.status == 0 ? 'text-[#cfba54]' : data?.status == 1 ? 'text-green' : 'text-red'}`}>{cardStatus[data?.status]}</p>
                                </div>
                              </li>
                              <li className='p-[12px_10px_12px] mb-[14px] flex justify-between cursor-pointer first:mt-[12px] bg-card rounded-[10px]'>
                                <div className="flex flex-col">
                                  <h5 className='text-white sm:text-[14px] text-[12px]'>{cardTypeJson[data?.type]}</h5>
                                  <p className='text-gray text-[12px]'>{formattedDate}</p>
                                </div>
                                <div className="flex items-end flex-col">
                                  <h5 className='text-white sm:text-14 text-12 uppercase'>{data?.tx_amount ? parseFloat(data?.tx_amount).toFixed(2) : '0.0'} {data?.tx_currency}</h5>
                                  <p className={`text-[12px] ${data?.status == 0 ? 'text-[#cfba54]' : data?.status == 1 ? 'text-green' : 'text-red'}`}>{cardStatus[data?.status]}</p>
                                </div>
                              </li>
                              </>
                            )
                          }) : <p className='text-gray text-center py-[10px]'>No Record Found</p>}
                      </ul>
                    </div>

                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

    </>
  )
}

export default SetTransectionListModal
