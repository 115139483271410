import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useContext, useEffect, useState } from 'react'
import IconClose from '../../assets/svg/IconClose'
import { Button } from '../ui/Button'
import Input from '../ui/Input'
import 'react-phone-input-2/lib/style.css'
import { Form, FormikProvider, useFormik } from 'formik'
import { useTranslation } from 'react-i18next'
import * as Yup from "yup";
import { SEND_API_URL, SWAP_API_URL } from '../../api/api'
import { useMutation } from 'react-query'
import useAxiosPrivate from '../../api/useAxiosPrivate'
import { useSnackbar } from 'notistack'
import { authStore } from '../../context/AuthProvider'
import { RadioGroup } from '@headlessui/react'
import IconDone from '../../assets/svg/IconDone'
import SendConfirmationDialog from '../ui/SendConfirmationDialog'
import { AuthSocketContext } from '../../context/AuthSocketProvider'
import SendInformation from '../ui/SendInformation'
import Swal from 'sweetalert2'
const SendNftModal = ({ open, handleClose, handleOpenSuccessModal, nftDetails }) => {
  const { t } = useTranslation();
  const axiosInstance = useAxiosPrivate();
  const { enqueueSnackbar } = useSnackbar();
  const { user, encKey, set } = authStore();
  const [openLevel, setOpenLevel] = useState(false);
  const [level, setLevel] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [plan, setPlan] = useState(undefined);
  const [feeLevel, setFeeLevel] = useState(undefined);
  const [selectedFeeLevel, setSelectedFeeLevel] = useState(null);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [userInput, setUserInput] = useState(null)
  const { setConfirmSendNft, approveReject, setApproveReject } = useContext(AuthSocketContext);
  const [openSendInfo, setOpenSendInfo] = useState(false)
  const keysArray = Object.keys(level ? level : '');

  const sendNftsSchema = (t, nftDetails) => Yup.object().shape({
    address: Yup.string().required(t('Please_enter_address')),
    amount: Yup.number().test('requiredIfNftDetails', 'Amount is required', function (value) {
      if (nftDetails && nftDetails.standard === "ERC1155") {
        return value !== undefined && value !== null;
      }
      return true;
    })
  });


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      address: "",
      amount: "",

    },
    validationSchema: sendNftsSchema(t, nftDetails),
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading(true)
      setUserInput(values);
      const validAddressData = {
        currency: nftDetails?.blockchainDescriptor == "POLYGON" ? "MATIC" : nftDetails?.blockchainDescriptor,
        address: values?.address,
      }
      await validateAddress({ ...validAddressData, values })
    }
  });


  // Validate address api

  const { mutateAsync: validateAddress } = useMutation(
    async (data) => {
      return axiosInstance.post(SEND_API_URL.validateAddress, data).then((res) => res.data);
    },
    {
      onSuccess: async (data, variables) => {
        if (nftDetails.standard === "ERC1155") {
          // Api Step 2: get transactionEstimateFee 
          const estimateAddData = {
            assetId: nftDetails?.blockchainDescriptor == "POLYGON" ? "MATIC_POLYGON" : nftDetails?.blockchainDescriptor,
            amount: variables?.values?.amount,
            // sourceId: user?.vaultAccountAssetId,
            destinationIdOrAddress: variables?.values?.address
          };

          console.log("estimateAddData", estimateAddData);
          await transactionEstimateFee(estimateAddData);
        }
      },
      onError: async (error) => {
        if (error?.response?.data.message) {
          enqueueSnackbar(error?.response?.data.message, {
            variant: "error",
            anchorOrigin: { vertical: "top", horizontal: "right" },
            autoHideDuration: 2000,
          });
        }
      },
    }
  );


  // TransactionEstimateFee api
  const { mutateAsync: transactionEstimateFee } = useMutation(
    async (data) => {
      try {
        const response = await axiosInstance.post(SWAP_API_URL.transactionEstimateFee, data);
        if (response?.status == 200) {
          setIsLoading(false);
          setLevel(response?.data);
          setOpenLevel(true)
        }
      } catch (error) {
        setIsLoading(false);
        enqueueSnackbar(error?.response?.data.message, {
          variant: "error",
          anchorOrigin: { vertical: "top", horizontal: "right" },
          autoHideDuration: 2000,
        });
      }
    },
    {
      onSuccess: async (data) => {

      }
    }
  );

  const handleSubmitConfirm = () => {
    const payabelAmount = parseFloat(userInput?.amount) + parseFloat(selectedFeeLevel?.networkFee);
    // if (parseFloat(payabelAmount) > parseFloat(selectedCurrencyUserAmount?.balance)) {
    //   enqueueSnackbar("Your current balance is lower than your payable amount", {
    //     variant: "error",
    //     anchorOrigin: { vertical: "top", horizontal: "right" },
    //     autoHideDuration: 2000,
    //   });
    //   return
    // }
    const data = {
      userId: user?._id,
      currencyName: nftDetails?.blockchainDescriptor == "POLYGON" ? "MATIC_POLYGON" : nftDetails?.blockchainDescriptor,
      receiverAddress: userInput?.address,
      amount: (userInput?.amount).toString(),
      feesLevel: selectedFeeLevel?.level,
      feesAmount: selectedFeeLevel?.networkFee,
      encryptedKey: encKey,
      currencyCode: nftDetails?.blockchainDescriptor == "POLYGON" ? "MATIC" : nftDetails?.blockchainDescriptor,
      feeNativeCode: nftDetails?.blockchainDescriptor == "POLYGON" ? "MATIC" : nftDetails?.blockchainDescriptor,
      tokenId: '',
      nftType: nftDetails?.standard
    }

    setOpenConfirmation(false)
    setOpenSendInfo(true)
    setConfirmSendNft(data)
    resetForm()
  }

  useEffect(() => {
    const defaultFeeLevel = level?.low;
    setPlan(defaultFeeLevel);
    const feeLevelsData = {};
    for (let key of keysArray) {
      const property = level[key];
      property.level = key;
      feeLevelsData[key] = property;
    }
    setFeeLevel(feeLevelsData);
  }, [level]);


  const handleGetFeeLevel = async (value) => {
    setSelectedFeeLevel(value)
    setOpenConfirmation(true)
  }


  const handleClickSubmit = () => {
    if (plan) {
      handleGetFeeLevel(plan)
    } else {
      enqueueSnackbar("Please select estimate fees to proceed", {
        variant: "error",
        anchorOrigin: { vertical: "top", horizontal: "right" },
        autoHideDuration: 2000,
      });
    }
  }
  const handleCloseConfirmation = () => {
    setOpenConfirmation(false)
    resetForm();
    setOpenLevel(false);
    setLevel(null)
  }

  const {
    handleSubmit,
    getFieldProps,
    errors,
    touched,
    resetForm
  } = formik;

  const handleCloseInfoDialog = () => {
    setOpenSendInfo(false)
  }
  console.log('openLevel', openLevel);

  useEffect(() => {
    if (approveReject !== null) {
      if (approveReject?.status == 1) {
        Swal.fire({
          icon: "success",
          title: "Your transaction request has been approved.",
          timer: null,
          showConfirmButton: true
        });

      } else if (approveReject?.status == 2) {
        Swal.fire({
          icon: "error",
          title: "Nft Transaction?",
          text: "Your transaction request has been rejected.",
          timer: null,
          showConfirmButton: true
        });
      }
      setOpenLevel(false)
      setApproveReject(null)
      setSelectedFeeLevel(null)
      setOpenConfirmation(false)
      resetForm();
      setConfirmSendNft(null)
      setOpenSendInfo(false)
      handleClose();
      set({ isNftSend: false })
    }
  }, [approveReject])


  const handleModalClose = () => {
    resetForm();
    setOpenLevel(false);
    setLevel(null);
    setFeeLevel(undefined)
    setPlan(undefined);
    handleClose();
  }
  return (
    <Transition appear show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {
        resetForm();
        return;
      }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#000000cd]  bg-opacity-25" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform rounded-[30px] bg-dark overflow-hidden border border-light-100 shadow-xl transition-all">
                <div className="flex justify-center p-8 bg-card border-b-light-100 border-b">
                  <p className='text-white ms-auto'>Send NFT - {nftDetails?.blockchainDescriptor}</p>
                  <IconClose onClick={() => {
                    handleClose();
                    resetForm();
                    setOpenLevel(false)
                    setFeeLevel(undefined)
                    setLevel(null)
                    setIsLoading(false)
                    return;
                  }} className="text-white w-[18px] ms-auto cursor-pointer" />
                </div>

                <FormikProvider value={formik}>
                  <Form autoComplete="off" onSubmit={handleSubmit}>
                    <div className="p-12">
                      <Input lable="Address" name="address" placeholder="Enter Address"
                        {...getFieldProps('address')}
                        errorMsg={errors.address && touched.address ? errors.address : null} />
                      {
                        nftDetails?.standard === "ERC1155" && (
                          <Input name="amount" lable="Amount" placeholder="Enter Amount"
                            {...getFieldProps('amount')}
                            errorMsg={errors.amount && touched.amount ? errors.amount : null} />
                        )
                      }

                      {openLevel &&
                        <div className="mt-[20px] p-[16px] border border-light-100 max-w-[400px] rounded-[10px]">
                          <p className='text-[18px] font-600 text-white'>{t('Choose_Estimate_fee')}</p>
                          <div className="content p-4">
                            <RadioGroup className="flex flex-col text-18 gap-4" value={plan} onChange={setPlan}>
                              {keysArray?.map((item, index) =>
                                feeLevel && feeLevel[item] && (
                                  <RadioGroup.Option key={index} value={feeLevel[item]} className="cursor-pointer">
                                    {({ checked }) => (
                                      <div className="flex gap-2 text-14 font-500 text-white">
                                        <div
                                          className={`flex justify-center items-center w-8 h-8 rounded-full text-gray ${checked ? "bg-theme" : "border"
                                            }`}
                                        >
                                          {checked && <IconDone className="w-[9px] text-black" />}

                                        </div>
                                        {`${item.charAt(0).toUpperCase()}${item.slice(1)}`}
                                        <div>
                                          <div className='!text-white'>{` - ` + parseFloat(feeLevel[item]?.networkFee)?.toFixed(8) + ``} {/* {selectedCoin?.tokenCode ? selectedCoin?.tokenCode : selectedCoin?.code} */}</div>
                                        </div>
                                      </div>

                                    )}
                                  </RadioGroup.Option>
                                )
                              )}
                            </RadioGroup>

                          </div>
                        </div>}
                      <div className="mt-auto">
                        {
                          openLevel ?
                            <div className="grid sm:grid-cols-2 items-center gap-[40px] pt-[20px]">
                              <Button type='button' className="w-full !text-14 border text-black mt-2" color="error" onClick={handleModalClose}>Cancel</Button>
                              <Button type='button' className="w-full !text-14  border  text-black mt-2" onClick={handleClickSubmit}>Submit</Button>
                            </div> :
                            <Button className={`w-full mt-20 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`} size="sm" disabled={isLoading}>
                              {isLoading ? 'Loading...' : t('Continue')}
                            </Button>
                        }
                      </div>
                    </div>
                  </Form>

                </FormikProvider>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
        <SendConfirmationDialog open={openConfirmation} setOpen={setOpenConfirmation} handleClose={handleCloseConfirmation} feeLevel={selectedFeeLevel} handleSubmitConfirm={handleSubmitConfirm} /* userBalance={selectedCurrencyUserAmount} */ userInput={userInput} />
        <SendInformation open={openSendInfo} setOpen={setOpenSendInfo} handleClose={handleCloseInfoDialog} />

      </Dialog>
    </Transition>
  )
}

export default SendNftModal
