import React, { useEffect, useState } from 'react'
import IcnWifi from '../../../assets/svg/IcnWifi'
import IcnGoldChip from '../../../assets/svg/IcnGoldChip'
import IcnMasterLogo from '../../../assets/svg/IcnMasterLogo'
import { useQuery } from 'react-query'
import { axiosInstance } from '../../../api/axiosInstance'
import { USER_API_URL } from '../../../api/api'
import { enqueueSnackbar } from 'notistack'

const VirtualCard = ({ detail,setBalance }) => {
    const [flip, setFlip] = useState(false);
    

    const numberFormate = (number) => {
        const set1 = number.slice(0, 4)
        const set2 = number.slice(4, 6)
        const set3 = number.slice(-4)
        return set1 + ' ' + set2 + '** **** ' + set3
    }


    useEffect(() => {
        const fetchToData = async () => {

            const data = {
                card_id: detail?.cardId,
            };
            await axiosInstance.post(USER_API_URL.balanceInquiry, data).then((result) => {
               setBalance(result?.data?.current_balance)
            }).catch((error) => {
              console.log({error})
            });

        };

        fetchToData();
    }, [detail?.cardId]);

    return (
        <div class="flip-card xss:h-[210px] h-[180px] xss:w-[325px] w-full mx-auto cursor-pointer" onClick={() => setFlip(!flip)}>
            <div class="flip-card-inner relative h-full w-full transition-transform duration-700" style={{ transform: `${flip ? 'rotateY(180deg)' : 'rotateY(0deg)'}` }}>
                <div className={`flip-card-front flex flex-col justify-between rounded-[24px] xss:p-[16px_28px] p-[10px_18px] overflow-hidden absolute h-full w-full`}>
                    <div className="flex flex-col">
                        <div className="flex items-center justify-between">
                            <img
                                src={require('../../../assets/images/card/card-logo.svg').default}
                                className=""
                                alt=""
                            />
                            <p className='text-[10px] font-500 tracking-wider text-[#B3B3B3]'>Virtual Card</p>
                        </div>
                        <div className="flex">
                            <span className=''>
                                <IcnGoldChip className='h-[43px] w-[45px]' />
                            </span>
                            <span className='pt-[5px]'>
                                <IcnWifi className="h-[27px] w-[27px] text-white" />
                            </span>
                        </div>
                    </div>
                    <div className="flex items-center justify-between">
                        <p className='example'>{detail?.firstName} {detail?.lastName}</p>
                        <span>
                            <IcnMasterLogo className='h-[50px] w-[73px]' />
                        </span>
                    </div>
                </div>
                <div className={`flip-card-back flip-card-front rounded-[24px] py-[10px] overflow-hidden absolute h-full w-full flex flex-col`}>
                    <p className='text-[8px] font-500 text-white/80 xss:tracking-[0.5px] tracking-[0.2px] text-center'>International support contact on support@oppiwallet.com</p>
                    <div className="bg-white/30 xss:h-[31px] h-[20px] w-full xss:mt-[10px] mt-[6px]" />
                    <div className="xss:px-[26px] px-[18px] py-[10px] flex flex-col justify-between flex-grow">
                        <div className="bg-white w-[70%] rounded-[5px] overflow-hidden flex">
                            <img
                                src={require('../../../assets/images/card/scratch.svg').default}
                                className="h-full max-w-[80%] object-cover"
                                alt=""
                            />
                            <p className='text-black xss:text-[14px] text-[12px] font-600 leading-[1.6] w-[20%] flex items-center justify-center tracking-[0.5px]'>123</p>
                        </div>
                        <p className='text-[#B3B3B3] text-[8px] font-500 tracking-[0.5px]'>Authorised signature, not valid unless signed</p>
                        <h4 className='xss:text-[16px] text-[14px] font-500 text-white xss:tracking-[3px] tracking-[2px]'>{numberFormate(detail?.cardNumber)}</h4>
                        {/* <p className='text-white/30 text-[8px] font-500 tracking-[0.8px]'>VALID THRU <span className='xss:text-[16px] text-[14px] text-white ps-[6px]'>10/28</span></p> */}
                        <p className='text-[8px] font-500 text-white tracking-[0.5px]'>Issued under license by Mastercard International.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VirtualCard